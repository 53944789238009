<template>
  <div class="message_home_page">
    <myTable title="下载文件" :columns="columns" :list="downList">
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="down(item.data.row)">
            {{ item.data.row.isDown ? "已下载" : "下载" }}
          </div>
          </div>
      </template>
    </myTable>
    <comPagination :params="downParams" @changePage="downChangePage"></comPagination>
    <myTable title="上传文件" :columns="columns" :list="uploadList">
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="update(item.data.row)">更新</div>
        </div>
      </template>
    </myTable>
    <comPagination :params="updataParams" @changePage="updataChangePage"></comPagination>

    <el-button type="primary" style="padding: 0.15rem 0.2rem;display: block;font-size: 0.24rem;margin: 0 auto;" @click="alertShow = true">我要上传</el-button>
    <!-- 上传弹窗 -->
    <el-dialog title="上传" @close="closeAlert" :visible.sync="alertShow">
      <el-form :model="form">
        <el-form-item style="margin-bottom:0.2rem" label="文件名" :label-width="formLabelWidth">
          <el-input style="width:7rem" v-model="form.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传文件" style="margin-bottom:0.2rem" :label-width="formLabelWidth">
          <!-- 上传文件 -->
          <el-input style="width:6rem" v-model="form.upload" disabled clearable></el-input>
          <el-upload :headers="{token: $store.state.userInfo.token}" class="upload" :action="constant.UPLOAD_URL + '/file/upload'" :show-file-list="false" :on-success="uploadSuccess" :on-progress="onProgress"
            :before-upload="beforeImgUpload"
            ref="uploadImg"
          >
            <el-button style="padding: 0.09rem;" type="primary">点击上传</el-button>
          </el-upload>
          <el-progress v-show="filePercent" :percentage="filePercent" style="width:7rem;margin:0.1rem 0"></el-progress>
        </el-form-item>
        <el-form-item v-if="form.isVideo == 1" label="上传视频" style="margin-bottom:0.2rem" :label-width="formLabelWidth">
          <!--  -->
          <el-input style="width:6rem" v-model="form.videoUpload" disabled clearable></el-input>
          <el-upload :headers="{token: $store.state.userInfo.token}" class="upload" :action="constant.UPLOAD_URL + '/vod/video/uploadAlyiVideo'" :show-file-list="false" :on-success="uploadVideoSuccess" :on-progress="onVideoProgress"
            :before-upload="beforeVideoUpload"
            ref="uploadVideo"
          >
            <el-button style="padding: 0.09rem;" type="primary">点击上传</el-button>
          </el-upload>
          <el-progress v-show="videoPercent" :percentage="videoPercent" style="width:7rem;margin:0.1rem 0"></el-progress>
        </el-form-item>
        <el-form-item label="是否上传视频" :label-width="formLabelWidth">
          <el-radio-group v-model="form.isVideo" @change="changeIsVideo">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="padding: 0.15rem 0.2rem;display: inline-block;font-size: 0.18rem;" @click="closeAlert">取 消</el-button>
        <el-button style="padding: 0.15rem 0.2rem;display: inline-block;font-size: 0.18rem;" type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import myTable from "@/components/admin/table.vue";
  import comPagination from "@/components/admin/pagination.vue";
  export default {
    name: "letter",
    components: {
      comPagination,
      myTable,
    },
    data() {
      return {
        //班级列表
        columns: [
        {
          prop: "title",
          label: "名称",
        },
        {
          prop: "createtime",
          label: "时间",
        }, ],
        downList: [],
        uploadList: [],
        //分页
        downParams: {
          page: 0,
          size: 10,
          total: 0,
        },
        updataParams: {
          page: 0,
          size: 10,
          total: 0,
        },

        form: {
          title: '',
          upload: '',
          isVideo: 0,
          videoUpload: ''
        },
        filePercent: 0,
        videoPercent: 0,
        formLabelWidth: '1.2rem',

        alertShow: false,
        isUpdate: false, //上传或更新
        tabUplad:{}
      };
    },
    created() {},
    mounted() {
      this.init();
    },
    methods: {
      //初始化
      init() {
        this.getDownInfo();
        this.getUploadInfo();
      },
      getDownInfo() {
        let data = {
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.findDocDownList(this.downParams, data).then((res) => {
          this.downParams.total = res.data.total;
          this.downList = res.data.list.map((item) => {
            let data = { ...item.doc, isDown: item.isDown };
            data.createtime = this.common.dateFormat(data.createtime * 1000);
            return data;
          });
        });
      },
      getUploadInfo() {
        let data = {
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.findDocUploadList(this.updataParams, data).then((res) => {
          this.updataParams.total = res.data.total;
          this.uploadList = res.data.list.map((item) => {
            let data = { ...item };
            data.createtime = this.common.dateFormat(data.createtime * 1000);
            data.normalUrl = data.normalUrl === ' ' ? '' : data.normalUrl;
            return data;
          });
        });
      },
      //更新
      update(val) {
        this.alertShow = true
        this.isUpdate = true
        this.tabUplad = val
        this.form.title = val.title
        this.form.upload = val.attachfile
        this.form.videoUpload = val.normalUrl
        if(val.normalUrl){
          this.form.isVideo = 1
        }
      },
      //下载
      down(val) {
        console.log(val);
        let a = document.createElement('a')
        a.href = this.constant.URL + val.attachfile
        a.click()
        val.isDown = true
      },
      // 上传成功
      uploadSuccess(res) {
        this.form.upload = res.data.filename
      },
      uploadVideoSuccess(res) {
        this.form.videoUpload = res.data;
        this.videoPercent = 100;
        console.log(this.videoPercent,'this.videoPercent')
        if (this.videoPercent == 100) {
          this.videoPercent = 0
        }
      },
      //上传进度
      onProgress(event, file) {
        this.filePercent = parseInt(file.percentage)
        if (this.filePercent == 100) {
          this.filePercent = 0
        }
      },
      onVideoProgress(event, file) {
        this.videoPercent = parseInt(file.percentage)
        if (this.videoPercent == 100) {
          this.videoPercent = 0
        }
      },
      //分页 todo 待测试
      downChangePage(val) {
        this.downParams.page = val;
        this.getDownInfo();
      },
      updataChangePage(val) {
        this.updataParams.page = val;
        this.getUploadInfo();
      },
      // 关闭弹窗
      closeAlert() {
        this.alertShow = false
        this.form = {
          title: '',
          upload: '',
          isVideo: 0,
          videoUpload: ''
        }
        this.filePercent = 0
        this.videoPercent = 0
        this.isUpdate = false
      },
      //提交上传
      submit() {
        if (!this.form.title.trim()) {
          this.$notice({
            message: '请输入文件名'
          }).isShow()
          return;
        }else{
          if(!this.common.validateLength(this.form.title.trim(),50)){
            this.$notice({
              message: '请输入正确格式文件名'
            }).isShow()
            return;
          }
        }
        if (!this.form.upload && !this.form.videoUpload) {
          this.$notice({
            message: '请上传文件或视频'
          }).isShow()
          return;
        }
        // true 上传 false 更新
        let data = {
          title: this.form.title,
          attachfile: this.form.upload,
          normalUrl: this.form.videoUpload
        }
        if (!this.isUpdate) {
          this.api.admin.uploadDoc(data).then(res => {
            if (res.flag) {
              this.$notice({
                type: 'success',
                message: res.message
              }).isShow()
              this.closeAlert();
              this.getUploadInfo();
            } else {
                              this.$notice({
                  message: res.message
                }).isShow()
            }
          })
        }else{
          this.api.admin.doc(data,this.tabUplad.id).then(res => {
            if (res.flag) {
              this.$notice({
                type: 'success',
                message: res.message
              }).isShow()
              this.closeAlert();
              this.getUploadInfo();
            } else {
                              this.$notice({
                  message: res.message
                }).isShow()
            }
          })
        }
      },
      //切换是否上传视频
      changeIsVideo(val){
        console.log(val,'dddd')
        if(val === 1){
          this.form.videoUpload = '';
        }
      },
      //校验图片大小
      beforeImgUpload(file){
        //限制上传图片处理函数
        const isLt50M = file.size / 1024 / 1024 < 80;
        if (!isLt50M) {
          this.$message.error("上传图片大小不能超过 80MB!");
          // this.$refs.uploadImg.clearFiles();
          return false;
        }
      },
      beforeVideoUpload(file){
        //限制上传视频处理函数
        const isLt50M = file.size / 1024 / 1024 < 80;
        if (!isLt50M) {
          this.$message.error("上传视频大小不能超过 80MB!");
          // this.$refs.uploadVideo.clearFiles();
          return false;
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .message_home_page {
    .upload {
      display: inline-block;
      margin-left: 0.1rem;
    }
  }
</style>
<style lang="scss">